<template>
  <div>
    <div v-if="signed_bankid">
      <p>Signed BankID</p>
      <p>{{ bankid }}</p>
    </div>
    <div v-else>
      <BankIDOpenAppComponent 
        :return_url="`https://test.memlist.se/test/bankid-button`"
        :intent="'user'"
      />
    </div>
    
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import BankIDOpenAppComponent from '@/view/components/bankid/BankIDOpenAppComponent.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'TestBankIDButtonPage',
  components: {
    BankIDOpenAppComponent
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['isAuthenticated','isMemAuthenticated']),
  },
  async mounted() {
    this.nonce = this.$route.query.nonce;

    if (this.nonce) {
      this.fetch_returned_auth();
    }
    
  },
  data() {
    return {
      nonce: null,
      signed_bankid: false,
      bankid: null
    };
  },
  watch: {
    
  },
  methods: {
    async fetch_returned_auth() {
      const res = await axios.post(`native/bankid/auth/returned`, {
        nonce: this.nonce
      });

      if (res.status === 200) {
        console.log('GOT OK!')

        this.signed_bankid = true;
        this.bankid = res.data.bankid;
      }
    }
  }
};
</script>
